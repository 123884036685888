<template>
  <base-layout :loadingFlag="$store.state.loading">
    <breadcrumbs :links="links" />
    <h1 class="title">Settlement Acceptance</h1>
    <p class="content">
      Your proposed payment has been approved (not including any possible
      convenience fee). Please review the following schedule of payments and
      click "{{ continueLabel }}" if acceptable. Click "{{ cancelLabel }}" to
      return to the Account screen.
    </p>
    <div class="buttons">
      <button
        class="button is-success"
        @click="createArr"
        :disabled="$store.state.loading"
      >
        {{ continueLabel }}
      </button>
      <router-link
        tag="button"
        class="button is-danger"
        :to="{ name: 'overTime' }"
      >
        {{ cancelLabel }}
      </router-link>
    </div>
    <p class="content spinner-wrapper" v-if="$store.state.loading">
      <span class="icon is-large has-text-info spinner">
        <font-awesome-icon icon="spinner" size="lg" spin />
      </span>
    </p>
    <schedule v-else 
      :arr="$store.state.arr.preview" 
      :include-penalty="instanceConfig.includePenalty"
      :include-interest="includeInterest(true)" />
  </base-layout>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Schedule from "@/components/arrangement/Schedule.vue";
import store from "@/store/index";

function unloadListener(event) {
  event.preventDefault();
  event.returnValue = "";
}

export default {
  components: { BaseLayout, Breadcrumbs, Schedule },
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapGetters("accounts", ["fullAccount"]),
    ...mapGetters("arr", ["includeInterest"])
  },
  data() {
    return {
      continueLabel: "Continue",
      cancelLabel: "Cancel",
      links: []
    };
  },
  created() {
    this.links = [
      { label: "Home", icon: "home", to: { name: "home" } },
      {
        label: "Make Payment",
        icon: "dollar-sign",
        to: { name: "makePayment", params: { id: this.$route.params.id } }
      },
      {
        label: this.instanceConfig.overTimeTitle,
        icon: "calendar",
        to: { name: "overTime", params: { id: this.$route.params.id } }
      },
      {
        label: "Settlement Acceptance",
        icon: "check",
        to: {
          name: "amortizationSchedule",
          params: { id: this.$route.params.id }
        },
        isActive: true
      }
    ];
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.state.instanceConfig &&
      Object.keys(store.state.instanceConfig).length === 0 &&
      store.state.instanceConfig.constructor === Object
    ) {
      next({
        name: "overTime",
        params: { instanceId: to.params.instanceId, id: to.params.id }
      });
    } else {
      window.addEventListener("beforeunload", unloadListener);
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  },
  methods: {
    async createArr() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch("arr/createArrangement");
        await this.$store.dispatch("pay/fetchPrefilledPayInfo", {
          instanceId: this.$route.params.instanceId,
          dbtNo: this.$route.params.id
        });
        this.$router.push({ name: "overTime.payment" });
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      }
    }
  }
};
</script>
